import { defineStore } from 'pinia'
import { ref } from 'vue'

const useMineStore = defineStore('mine', () => {
    const expInfo = ref({}); // 经验值
    const reputeList = ref([]); // 声望列表
    const userInfo = ref({}); // 用户信息
    const taskCount = ref(0); // 任务数量统计
    const IntegralCount = ref(0); // 积分数量统计
    const CouponCount = ref(0); // 卡券数量统计

    const orderStatus = ref('');// 点击的状态
    return { expInfo, reputeList, userInfo, taskCount, IntegralCount, CouponCount, orderStatus }
})
export default useMineStore;