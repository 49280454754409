import { api } from '../utils/api';
/** 获取厂商标签列表 ?? */
export async function getTagList(data) {
    return api('/web/ec/store/list', data, 'POST', false);
}

/** 获取厂商详情  ??*/
export async function getStoreDetail(data) {
    return api('/web/ec/store/detail', data, 'POST', false);
}
// zxb
// 获取所有普通标签
export async function getTagAll(data) {
    return api('/web/common/tag/all', data, 'POST', false, true, false);
}
