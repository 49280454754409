import { defineStore } from 'pinia';
import { getTagAll } from '../api/tag';
import { getManufacturerList } from '../api/manufacturer';
import { reactive } from 'vue';
const useTagStore = defineStore('tag', () => {
  const state = reactive({
    tags: [], // 标签列表(标签+厂商名)
  });

  /**
   * 获取包括厂商在内的所有标签
   * @param {string} type 标签类型
   * @returns
   */
  function getAllTagsAction(type) {
    state.tags = [];
    // 获取标签列表
    getTagAll({
      slug: type,
      item: true,
      status: 1,
    }).then((res) => {
      if (res.length != 0) state.tags.push(...res[0].children);
      // 获取厂商列表
      getManufacturerList().then((res) => {
        if (res.length != 0) state.tags.push(...res);
      });
    });
    return state.tags;
  }
  return { ...state, getAllTagsAction };
});

export default useTagStore;
