import { defineStore } from 'pinia'
import { reactive } from 'vue'

const useTaskStore = defineStore('task', () => {
    const state = reactive({
        taskList: [], // 任务列表
    })

    function setTaskListAction(data) {
        state.taskList = data;
    }
    return { ...state, setTaskListAction }
})
export default useTaskStore;