import useMineStore from './mine';
import useTaskStore from './task';
import useTagStore from './tag';
// 统一导出useStore方法
export default function useStore() {
    return {
        mineStore: useMineStore(),
        taskStore: useTaskStore(),
        tagStore: useTagStore(),
    }
}